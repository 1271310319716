body {
  padding: 0;
  margin: 0;
  background-color: #ddd;
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 15px;
  line-height: 25px;
  color: #333;
}

.container {
  width: 800px;
  margin: 30px auto;
  background-color: #efede8;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.my-3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pl-3 {
  padding-left: 10px;
}

.mx-3 {
  margin-left: 15px;
  margin-right: 15px;
}

.mt-1 {
  margin-top: 5px;
}

.mt-5 {
  margin-top: 30px;
}

.my-1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-1 {
  margin-bottom: 5px;
}

.pb-5 {
  padding-bottom: 30px;
}

h1 {
  font-weight: 500;
  font-size: 2em;
}

.form-item {
  width: 100%;
  display: block;
}

.form-item > div {
  padding: 15px 0;
  display: inline-block;
}

.form-item > div > span {
  padding: 0 10px;
  display: block;
}

.form-item:nth-child(even) {
  background-color: #ddd;
}

.form-item-header {
  width: 100%;
  background-color: #d0626d;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
}

.form-item-header > div {
  margin: 10px 0;
  display: inline-block;
}

.form-item-header span {
  display: block;
}

input[type="checkbox"] {
  transform:scale(2.5, 2.5);
  -webkit-transform:scale(2.5, 2.5);
  -ms-transform: scale(2.5, 2.5);
  -moz-transform: scale(2.5, 2.5);
  -o-transform: scale(2.5, 2.5);
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.btn {
  display: inline-block;
  padding: 15px 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  border-radius: 3px;
  color: #fff;
  margin: 0 5px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

.btn:focus {
  outline: none;
}

.btn-primary {
  background: #d0626d;
}

.btn-primary:hover {
  background: #ad424e;
}

.btn-secondary {
  border-color: #ccc;
  color: #666;
}

.btn-secondary:hover {
  background: #ccc;
}

.banner-mobile {
  display: none;
}

hr {
  border-color: #ccc;
  border-style: solid;
}

footer {
  width: 800px;
  margin: 0 auto;
  font-size: 13px;
}

.text-muted {
  color: #6c757d;
}

@media (max-width: 800px) {
  h3 {
    font-size: 1em;
  }
  body {
    font-size: 14px;
    line-height: 20px;
  }

  .container {
    width: auto;
    margin: 30px;
  }

  footer {
    width: auto;
    margin: 0 30px;
  }

  .btn {
    width: 100%;
    margin: 5px 0;
  }

  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: block;
  }
}
